import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState, InitNotifications, LogoutAuthUser, MarkNotificationRead, NotificationState, NotificationStateModel, SetOrganizationContext, AuthStateModel } from '@app/auth';
import { HostNavigator, WindowRef } from '@core/browser';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, interval, pipe } from 'rxjs';
import { debounceTime, scan, takeUntil, map } from 'rxjs/operators';
import { Dialog } from '@core/material';
import { AuthProfileFormDialog } from '@app/auth/components/auth-profile/auth-profile-form.dialog';
import {User, Organization, Notification, Sample, CustomProtocolService} from '@core/data';
import { InitOrganizationSubscription, OrganizationSubscriptionState, OrganizationSubscriptionStateModel } from '@app/admin/organization-detail/organization-subscription.state';
import { OrganizationSubscriptionHandler } from '@core/data/globals/subscriptions';
import { environment } from 'environments/environment';

@Component({
    selector: 'pv-dashboard-view',
    templateUrl: './dashboard.view.html',
    host: {
        class: 'pv-dashboard-view'
    },
    changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardView implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked{

    @Select(AuthState)
    auth$: Observable<AuthStateModel>;

    @Select(NotificationState)
    notifications$: Observable<Notification[]>;

    // Subscriptions
    @Select(OrganizationSubscriptionState)
    subscriptionState$: Observable<OrganizationSubscriptionStateModel>;

    selectedOrgIndex$: Observable<number>;

    //Initialised to false
    orgSubscriptionStatus:OrganisationSubscriptionStatus = {
        tastings: false,
        evaluation: false,
    };

    private _notficationViewIndex$ = new Subject<number>();
    private _destroy$ = new Subject();
    isLoading: boolean;

    constructor(
        private _apiNav: HostNavigator,
        private _router: Router,
        private _store: Store,
        private _window: WindowRef,
        private _dialog: Dialog,
        private _custom_protocolService: CustomProtocolService,
        private _subscriptionHandler: OrganizationSubscriptionHandler,
    ){}

    ngOnInit(){

        /**
         * Mark notifications as read
         */
        this._notficationViewIndex$
            .pipe(
                debounceTime(300),
                scan((acc: number[], index: number, i) => {

                    if(acc.indexOf(index) === -1){
                        acc.push(index);
                    }

                    return acc;

                }, []),
                debounceTime(1500),
            )
            .subscribe(indexes => {

                indexes.sort((a, b) => a - b)

                const notifications: NotificationStateModel = this._store.selectSnapshot(NotificationState);
                const viewed: Notification[] = [];

                indexes.forEach(viewedFirstIndex => {
                    for(let i = viewedFirstIndex; i < (viewedFirstIndex + 4); i++){
                        if(notifications.items[i] !== undefined && viewed.indexOf(notifications.items[i]) === -1){
                            viewed.push(notifications.items[i]);
                        }
                    }
                });

                const read = viewed.filter(notification => notification.read_at === null);

                if(read.length > 0){
                    this._store.dispatch(new MarkNotificationRead(read));
                }
            });

        this.selectedOrgIndex$ =
            this.auth$.pipe(
                debounceTime(450),
                takeUntil(this._destroy$),
                map(state => {

                    if (state.selectedOrgKey) {
                        this._store.dispatch(new InitOrganizationSubscription(state.selectedOrgKey)).subscribe(complete => {
                            setTimeout(() => {
                                this.toggleOrgStatus(this._subscriptionHandler.isSubscribedToEvaluation(), this._subscriptionHandler.isSubscribedToTastings(), false)
                            }, 0);
                        });
                    }

                    if(state.user && state.user.organizations && state.user.organizations.length > 0){
                        return state.user.organizations.findIndex(org => {
                            return org.key === state.selectedOrgKey;
                        });
                    }

                    return -1;
                })
            );

    }

    ngAfterViewInit(){
        //todo: There should better place to initial the protocals
        this._custom_protocolService.allProtocols();
    }
    ngAfterContentChecked() {
        this.toggleBeamerSelector(true);
    }

    ngOnDestroy(){
        this._notficationViewIndex$.complete();
        this._destroy$.next();
        this._destroy$.complete();
        this.toggleBeamerSelector(false);
    }

    openAdminDashboard(){
        this._apiNav.openWindow('admin');
    }

    logout(){
        this._store.dispatch(new LogoutAuthUser);
        this._router.navigate(['login']);
    }

    navigate(route: string){
        this._router.navigateByUrl(route);
    }

    selectOrg(org: Organization){
        this.toggleOrgStatus(false, false, true)
        this._store.dispatch(new SetOrganizationContext(org.key));
    }

    toggleOrgStatus(evlas: boolean, tastings: boolean, loading: boolean) {
        this.orgSubscriptionStatus = {
            evaluation: evlas,
            tastings: tastings,
        }

        this.isLoading = loading;
    }

    markAsRead(notification: Notification | Notification[]) {

        if(Array.isArray(notification)){
            this._store.dispatch(new MarkNotificationRead(notification));
        }else{
            this._store.dispatch(new MarkNotificationRead([notification]));
        }
    }

    reloadNotifications(event: MouseEvent){

        event.preventDefault();
        event.stopPropagation();
        this._store.dispatch(new InitNotifications());
    }

    selectNotification(event: MouseEvent, notification: Notification){

        event.preventDefault();
        event.stopPropagation();

        if(notification.data.actionUrl){
            this._window.navigateUrl(notification.data.actionUrl);
        }
    }

    onNotificationScroll(firstIndex: number){
        this._notficationViewIndex$.next(firstIndex);
    }

    canWrite(role: string) {
        return (role === "admin" || role === "owner");
    }

    updateProfile(){
        this._dialog.open(AuthProfileFormDialog);
    }

    private toggleBeamerSelector(show: boolean) {
        let beamerSelector = document.getElementById('beamerSelector');

        if (beamerSelector) {
            beamerSelector.style.visibility = show ? 'visible' : 'hidden';
        }
    }
}

//Used to keep track of subscription status on front-end
export interface OrganisationSubscriptionStatus {
    tastings: boolean,
    evaluation: boolean,
}