<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Measurements</h2>
        <h3 class="dialog-subtitle">
            Sample {{ state.data ? state.data.sample.code : '' }},
            evaluation {{ state.data ? state.data.label : '' }}
        </h3>
    </div>
    <div mat-dialog-content>

        <form id="measurements_form"
              class="measures-form"
              [formGroup]="formGroup"
              #measurementsForm="ngForm"
              (ngSubmit)="attempt()"
              autocomplete="disabled"
              novalidate>
            <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">

                <!-- Tabs (for each crop subject) -->
                <mat-tab *ngFor="let tab of tabs; trackBy: trackTab"
                         [disabled]="state.status === 'LOADING'">

                    <ng-template mat-tab-label>
                        <span
                              [class.ng-invalid]="formGroup.get(tab.subject.id).invalid">{{tab.subject.label}}</span>
                    </ng-template>

                    <ng-template matTabContent>

                        <!-- MEASURE TABLE -->
                        <table class="measure-table" [formGroupName]="tab.subject.id" (copy)="handleCopy($event)">
                            <!-- HEADER ROW -->
                            <tr class="table-row">
                                <ng-container *ngFor="let measure of tab.measures; let i = index">
                                    <!-- INDEX COL -->
                                    <th class="measures-form-header" *ngIf="i == 0" id="header-0">
                                        Index
                                        <div class="measure-unit">#</div>
                                    </th>

                                    <!-- MEASURES -->
                                    <th class="measures-form-header" id="header-{{i + 1}}">
                                        {{ measure.label }}
                                        <div class="measure-unit">
                                            {{measure.min}} - {{ measure.max }} {{ measure.unit }}
                                        </div>
                                    </th>
                                </ng-container>
                            </tr>

                            <!-- TABLE DATA -->
                            <ng-container *ngFor="let tabIndex of tab.indexes;">
                                <tr>
                                    <td class="measures-form-index" id="index-{{tabIndex}}-0">{{ tabIndex + 1 }}</td>

                                    <!-- MEASURE DATA -->
                                    <ng-container *ngFor="let measure of tab.measures; let i = index" [formArrayName]="measure.id">
                                        <td class="measures-form-field" id="{{measure.id}}-{{tabIndex}}-{{i + 1}}">
                                            <input type="text"
                                                [name]="measure.id + '-' + tabIndex"
                                                [formControlName]="tabIndex"
                                                autocomplete="off"
                                                (paste)="handlePaste($event, tab.subject.id, tabGroup.selectedIndex)"
                                                id="c_{{i}}_r_{{tabIndex}}"
                                            >
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </table>

                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </form>

    </div>

    <pv-form-actions mat-dialog-actions
                     [form]="measurementsForm"
                     [status]="state.status"
                     (cancel)="cancel()">

        <!-- Add Row -->
        <button pvFormAction
                mat-icon-button
                matTooltip="Add Row"
                (click)="increaseSize(tabGroup.selectedIndex)"
                color="accent"
                [disabled]="formGroup.disabled">
            <mat-icon>add_circle</mat-icon>
        </button>

        <!-- Remove Row -->
        <button pvFormAction
                mat-icon-button
                matTooltip="Remove Row"
                (click)="decreaseSize(tabGroup.selectedIndex)"
                color="accent"
                [disabled]="formGroup.disabled || this.getRowCount(tabGroup.selectedIndex) == 0">
            <mat-icon>remove_circle</mat-icon>
        </button>

        <!-- Upload File -->
        <button pvFormAction
                mat-flat-button
                (click)="openFileUploadDialog()"
                color="accent"
                matTooltip="Upload Measurements File"
                [disabled]="formGroup.disabled">
            Upload File
        </button>

    </pv-form-actions>

</ng-container>
