import { Injectable } from "@angular/core";
import { CustomProtocolService, EvaluationService, SampleType, Status, translateCommonErrorStatus } from "@core/data";
import { Crop, Protocol } from "@library";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";


export interface EvaluationTemplateFormStateModel {
    orgKey: string;
    sampleKeys: string[];
    status: Status;
    data: EvaluationTemplateData;
}

export interface EvaluationTemplateData {
    crop: Crop;
    type: SampleType;
    protocolOptions: Protocol[];
}

export interface EvaluationTemplateForm {
    protocol: string,
    measureLimit: number,
    crop: string,
    type: string
}

const DEFAULTS: EvaluationTemplateFormStateModel = {
    orgKey: null,
    sampleKeys: [],
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitEvaluationTemplateForm {
    static readonly type = "[EvaluationTemplateForm] Init";
    constructor(public orgKey: string, public sampleKeys?: string[]) {}
}

export class SubmitEvaluationTemplateForm {
    static readonly type = "[EvaluationTemplateForm] Submit";
    constructor(public data: EvaluationTemplateForm[]) {}
}

export class LoadCropProtocols {
    static readonly type = "[EvaluationTemplateForm] Load Protocols";
    constructor(public crop: Crop, public type: SampleType) {}
}

@State<EvaluationTemplateFormStateModel>({
    name: 'evaluation_template_form',
    defaults: DEFAULTS
})
@Injectable()
export class EvaluationTemplateState {

    @Selector()
    static data(state: EvaluationTemplateFormStateModel) {
        return state.data;
    }

    constructor(
        private _customProtocolService: CustomProtocolService,
        private _evalService: EvaluationService,
    ) { }

    @Action(InitEvaluationTemplateForm, {cancelUncompleted: true} )
    initEvaluationTemplateForm(ctx: StateContext<EvaluationTemplateFormStateModel>, action: InitEvaluationTemplateForm) {
        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK
        });
    }

    @Action(SubmitEvaluationTemplateForm)
    submitEvaluationTemplateForm(ctx: StateContext<EvaluationTemplateFormStateModel>, action: SubmitEvaluationTemplateForm) {
        return this._evalService.downloadEvaluationImportTemplate(action.data)
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "evals-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn('SampleIndexState: Evals Download Error ', error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            )
    }

    @Action(LoadCropProtocols, {cancelUncompleted: false})
    loadCropProtocols(ctx: StateContext<EvaluationTemplateFormStateModel>, action: LoadCropProtocols) {
        const state = ctx.getState();

        ctx.patchState({status: Status.LOADING});

        this._customProtocolService.protocolsByCropObserved(action.crop.id, action.type, true).subscribe(result => {
            ctx.patchState({
                status: Status.OK,
                data: {
                    crop: action.crop,
                    type: action.type,
                    protocolOptions: result,
                }
            })
        });
    }

}

