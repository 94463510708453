<ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            Extract Evaluation Import Template
        </h2>
    </div>

    <div mat-dialog-content>
        <ng-container *ngSwitchCase="'OK'">
                <ng-container [formGroup]="templateOptionsGroup">
                    <div class="top-actions">
                        <mat-form-field appearance="standard">
                            <mat-label>Crop</mat-label>
                            <mat-select formControlName="templateCrop">
                                <mat-select-trigger>
                                    <ng-container *ngIf="cropControl.value as crop">
                                        <mat-icon [svgIcon]="'common:' + crop.id" class="mat-18 mr-12">
                                        </mat-icon>
                                        <span>{{ crop.label }}</span>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option *ngFor="let crop of cropOptions" [value]="crop">
                                    <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                                    <span>{{ crop.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div>
                            <div class="type-toggle-wrapper">
                                <mat-icon svgIcon="common:plant_sample"></mat-icon>
                                <mat-slide-toggle formControlName="plant" matTooltip="Create plant sample template"></mat-slide-toggle>
                            </div>
                            <div class="type-toggle-wrapper">
                                <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                                <mat-slide-toggle formControlName="harvest" matTooltip="Create harvest sample template"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button mat-flat-button
                            color="accent"
                            type="button"
                            (click)="addCropTemplate()">
                                Add Crop Template
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="templateData && templateData.length > 0; else noTemplateCrops">
                    <div class="template-form">

                        <ng-container *ngFor="let data of templateData; index as i" [formGroup]="templatesControl.controls[i]">
                            <mat-card>
                                <div class="form-header">
                                    <div class="header-title">
                                        <mat-icon mat-card-avatar [svgIcon]="'common:'+data.crop.id" ></mat-icon>
                                        <h4>{{ data.crop.label }}</h4>
                                    </div>
                                    <mat-icon class="header-type" [svgIcon]="'common:'+data.type+'_sample'" ></mat-icon>
                                </div>

                                <mat-card-content class="card-form-wrapper">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Evaluation Protocol</mat-label>

                                        <!-- TODO make protocols searchable -->
                                        <mat-select formControlName="protocol" required>
                                            <mat-option *ngFor="let proto of data.protocolOptions" [value]="proto.id">
                                                <div class="protocol-option">
                                                    <div class="option-label">{{ proto.title }}</div>
                                                    <div class="option-icon">
                                                        <mat-icon class="mat-24" style="margin: 0;" [svgIcon]="'common:' + data.crop.id"></mat-icon>
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>Select an Evaluation Protocol</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Measurement Limit</mat-label>
                                        <input matInput type="number" formControlName="measureLimit">
                                        <span matSuffix>#</span>
                                        <mat-hint>Number of measurements to include for each evaluation</mat-hint>
                                        <mat-error>Measurement limit should be a number between 1 - 100</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-template #noTemplateCrops>
                    <div class="no-data-wrapper">
                        <mat-icon class="no-data-icon" mat-card-avatar [svgIcon]="'common:no_data'"></mat-icon>
                        <h3>No Crops Selected</h3>
                    </div>
                </ng-template>

        </ng-container>

        <ng-container *ngSwitchCase="'LOADING'">
            <pv-view-loader message="Loading template data..."></pv-view-loader>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div class="error-wrapper">
                <mat-card class="error-card">
                    <mat-card-title>An Error Occurred</mat-card-title>
                    <mat-card-actions align="end">
                        <button mat-button (click)="cancel()">Cancel</button>
                        <button mat-flat-button color="primary" (click)="reset()">Reload</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </ng-container>

    </div>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close [disabled]="templatesControl.disabled">Cancel</button>
        <button mat-flat-button color="primary" (click)="attempt()" [disabled]="templatesControl.disabled">Save</button>
    </mat-dialog-actions>
</ng-container>