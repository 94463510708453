import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";
import { Cultivar } from '@core/data';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';


@Component({
    selector: '[pvCultivarLine]',
    template: `
        <div class="option-wrapper" *ngIf="showCultivarDetail; else cultivarLine">
            <div>
                <mat-icon *ngIf="_cropIcon" [svgIcon]="_cropIcon"></mat-icon>
                <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
            </div>

            <div>
                <span class="text" [innerHtml]="_html"></span>

                <div class="inline-infobox" *ngIf="showCultivarDetail">
                    <span *ngIf="_licensee">{{ this._licensee }}</span>
                    <div class="cultivar-sample-count">
                        <ng-container *ngIf="_plantSampleCount" matTooltip="Associated Plant Sample Scion">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                            <span class="sample-count">&nbsp;{{ this._plantSampleCount }}</span>
                        </ng-container>
                        <ng-container *ngIf="_harvestSampleCount" matTooltip="Associated Harvest Sample Scion">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                            <span class="sample-count">&nbsp;{{ this._harvestSampleCount }}</span>
                        </ng-container>
                    </div>

                    <div class="cultivar-sample-count">
                        <ng-container *ngIf="_rootstockPlantSamplesCount" matTooltip="Associated Plant Sample Rootstock">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                            <span class="sample-count">&nbsp;{{ this._rootstockPlantSamplesCount }}</span>
                        </ng-container>
                        <ng-container *ngIf="_rootstockHarvestSampleCount" matTooltip="Associated Harvest Sample Rootstock">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                            <span class="sample-count">&nbsp;{{ this._rootstockHarvestSampleCount }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #cultivarLine>
            <div class="line-wrapper">
                <mat-icon *ngIf="_cropIcon" [svgIcon]="_cropIcon"></mat-icon>
                <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                <span class="text" [innerHtml]="_html"></span>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-line pv-model-line'
    }
})
export class CultivarLineComponent {

    _plantSampleCount = null;
    _harvestSampleCount = null;
    _rootstockPlantSamplesCount = null;
    _rootstockHarvestSampleCount = null;
    _licensee = null;

    @Input('pvCultivarLine')
    set cultivar(cultivar: Cultivar){
        this.getCultivarDescription(cultivar)
    }

    @Input('pvCultivarChip')
    set chipCultivar(cultivar: Cultivar){
        this.getCultivarDescription(cultivar, true)
    }

    @Input()
    showCultivarDetail: boolean;

    @Input()
    set graft(graft: string){
        switch(graft){
            case 'scion':
                this._graftIcon = 'common:flat_scion';
                break;
            case 'rootstock':
                this._graftIcon = 'common:flat_rootstock';
                break;
            default:
                this._graftIcon = null;
        }
    }

    @HostBinding('attr.title')
    _title: string;

    _graftIcon: string = null;
    _cropIcon: string = null;
    _html: string;

    constructor(private _formatter: SampleFormatter){}

    private getCultivarDescription(cultivar: Cultivar, isChip: boolean = false) {
        let showDetail = this.showCultivarDetail ? true : false;

        if (showDetail) {
            this._plantSampleCount = this._formatter.describePlantSampleCount(cultivar);
            this._harvestSampleCount = this._formatter.describeHarvetSampleCount(cultivar);
            this._rootstockPlantSamplesCount = this._formatter.describeRootstockPlantSamplesCount(cultivar);
            this._rootstockHarvestSampleCount = this._formatter.describeRootstockHarvestSampleCount(cultivar);
            this._licensee = this._formatter.describeLicensee(cultivar);
        }

        if (cultivar) {
            this._html = this._formatter.describeCultivar(cultivar, true, isChip);
            this._title = this._formatter.describeCultivar(cultivar, true, isChip);
            this._cropIcon = 'common:' + cultivar.cropId;
        } else {
            this._html = '';
            this._cropIcon = null;
        }
    }


}