import { BaseIndex, Cultivar, FilterPage, FilterSort, Index, Sample, Site, Status } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { TastingEvent } from "@core/data/types/tastings-event";

export interface TastingsEventIndexStateModel {
    //org context
    orgKey: string;

    // table/page data
    status: Status;
    data: TastingEvent[];
    total: number;

    // table/data options
    sortOptions: { id: string, label: string}[];
    pageSizeOptions: number[];
    baseIndexes: BaseIndex[];
    indexes: Index[];
    indexOptions: Array<Index|BaseIndex>;

    //filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingsEventIndexQuery;

    //selection
    selectedKeys: string[];
    selectedData: TastingEvent[];

    licenseeOptions: string[];

    //export result
    exports: {
        key: string;
        status: Status;
    }[];
}

export interface TastingsEventIndexQuery {
    search: string;
    sites: Site[];
    scionCultivars: Cultivar[];
    harvestSamples: Sample[];
    tastingsSamples: TastingSample[];
    scionCropId: string[];
    scionLicensee: string[];
}

export const TASTING_EVENT_SORT_OPTIONS: {id: string, label: string}[] = [
    //{ id: 'site', label: 'Site' },
    //{ id: 'birthDate', label: 'Harvest Date' },
    { id: 'title', label: 'Title' },
    { id: 'code', label: 'Code' },
    { id: 'venueName', label: 'Venue' },
    { id: 'eventDate', label: 'Event Date' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
];

export const DEFAULTS: TastingsEventIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    indexes: [],
    baseIndexes: [],
    indexOptions: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'eventDate',
        order: 'desc'
    },
    sortOptions: TASTING_EVENT_SORT_OPTIONS,
    pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
    query: {
        search: null,
        sites: [],
        scionCultivars: [],
        harvestSamples: [],
        tastingsSamples: [],
        scionCropId: [],
        scionLicensee: [],
    },
    exports: [],
    licenseeOptions: [],
    selectedKeys: [],
    selectedData: [],
}