import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Dialog, Snackbar } from "@core/material";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { EvaluationTemplateData, EvaluationTemplateFormStateModel, EvaluationTemplateState, InitEvaluationTemplateForm, LoadCropProtocols, SubmitEvaluationTemplateForm } from "./evaluation-import-template-form.dialog.state";
import { takeUntil } from "rxjs/operators";
import { Crop, Library, Protocol } from "@library";
import { LIBRARY } from "@app/evaluation/library";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { SampleType } from "@core/data";

export interface EvaluationTemplateFormDialogData {
    orgKey: string;
    sampleKeys?: string[];
}

@Component({
    selector: 'pv-evaluation-template-form-dialog',
    templateUrl: './evaluation-import-template-form.dialog.html',
    styleUrls: ['./evaluation-import-template-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-evaluation-template-form-dialog pv-fullscreen-dialog'
    }
})
export class EvaluationTemplateFormDialog implements OnInit, OnDestroy {

    @Select(EvaluationTemplateState)
    state$: Observable<EvaluationTemplateFormStateModel>;

    @Select(EvaluationTemplateState.data)
    data$: Observable<EvaluationTemplateData>;

    cropControl = new FormControl(null, [Validators.required]);
    plantControl = new FormControl(null);
    harvestControl = new FormControl(null);
    cropOptions: Crop[];

    evalProtocolControl: FormControl = new FormControl(null, [Validators.required]);
    measureLimitControl: FormControl = new FormControl(20, [Validators.required, Validators.min(1), Validators.max(100)]);

    templatesControl: FormArray = new FormArray([]);

    templateOptionsGroup: FormGroup = new FormGroup({
        templateCrop: this.cropControl,
        plant: this.plantControl,
        harvest: this.harvestControl,
    });

    templateType = [
        {label: 'Plant Sample', icon: 'plant_sample', value: SampleType.PLANT},
        {label: 'Harvest Sample', icon: 'harvest_sample', value: SampleType.HARVEST},
    ];

    templateData: EvaluationTemplateData[] = [];

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<EvaluationTemplateFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(MAT_DIALOG_DATA) public data: EvaluationTemplateFormDialogData,
        @Inject(LIBRARY) private _library: Library,
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitEvaluationTemplateForm(this.data.orgKey));

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (!data) return;

                const templateData = JSON.parse(JSON.stringify(data));

                if (this.templateData.find(template => template.crop.id == data.crop.id && template.type == data.type)) {
                    this._snackbar.error('Duplicate template found');
                    return;
                }

                this.templateData.push(templateData);
                this.templateOptionsGroup.reset();

                this.templatesControl.push(this.makeTemplateGroup(data.type, data.crop.id));
            })

        this.cropOptions = this._library.filterExcludedCrops();
    }

    addCropTemplate() {
        this.templateOptionsGroup.updateValueAndValidity();
        this.templateOptionsGroup.markAsDirty();
        this.templateOptionsGroup.markAllAsTouched();

        if (!this.templateOptionsGroup.valid) return this._snackbar.error('Crop is requiured to create a template');

        const form = this.templateOptionsGroup.value;

        let types: SampleType[] = [];

        if (form.plant) types.push(SampleType.PLANT);
        if (form.harvest) types.push(SampleType.HARVEST);

        if (types.length == 0) return this._snackbar.error('Either plant/harvest is required to create a template');

        for (let i = 0; i < types.length; i++) {
            this._store.dispatch(new LoadCropProtocols(form.templateCrop, types[i]));
        }
    }

    reset() {
        this._store.dispatch(new InitEvaluationTemplateForm(this.data.orgKey));
    }

    makeTemplateGroup(type :string, cropId: string): FormGroup {
        return new FormGroup({
            protocol: new FormControl(null, [Validators.required]),
            measureLimit: new FormControl(20, [Validators.required, Validators.min(1), Validators.max(100)]),
            type: new FormControl(type, [Validators.required]),
            crop: new FormControl(cropId, [Validators.required]),
        });
    }

    attempt() {
        this.templatesControl.updateValueAndValidity();
        this.templatesControl.markAsDirty();
        this.templatesControl.markAllAsTouched();

        if (!this.templatesControl.valid) return this._snackbar.error('Check your inputs and try again.');

        this.templatesControl.disable();

        this._store.dispatch(new SubmitEvaluationTemplateForm(this.templatesControl.value))
            .subscribe(result => {
                this.templatesControl.enable();
                this._snackbar.info('Template successfully downloaded');
                this.cancel();
            }, e => {
                this.templatesControl.enable();
                return this._snackbar.error('An error occurred while downloading the requested templates.');
            }, () => {
                this.templatesControl.enable();
            })
    }

    cancel() {
        this._dialogRef.close();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}