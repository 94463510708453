import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {API_BASE_URI} from '../http/api';
import {Collection} from '../http/collection';
import {DetailRequest, DetailRequestBuilder} from '../http/detail';
import {Filter, FilterBuilder} from '../http/filter';
import {Observable} from 'rxjs';
import {CustomProtocol, ProtocolColumnType} from '@core/data/types/custom-protocol';
import {Library, Protocol, ProtocolDictionary} from "@library";
import {LIBRARY} from "@app/evaluation/library";
import {SessionStorage} from "@core/browser";

@Injectable()
export class CustomProtocolService  {

    protocols: ProtocolDictionary;
    customProtocols: Protocol[] = [];
    customProtocolsList: CustomProtocol[] = [];

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
        @Inject(LIBRARY) private _library: Library,
        @Inject(SessionStorage) private _session: SessionStorage
    ) {
    }

    list(orgKey: string) {
        const fb = new FilterBuilder().setQuery('ownerOrgKey', orgKey);
        let params = {
            ...FilterBuilder.queryParams(fb.get()),
        };
        return this._http.get<Collection<CustomProtocol>>(`${this._baseUri}/custom-protocols`, {params});
    }

    query(filter: Filter, detail?: DetailRequest) {
        let params = {
            ...DetailRequestBuilder.queryParams(detail),
            ...FilterBuilder.queryParams(filter),
        };
        return this._http.get<Collection<CustomProtocol>>(`${this._baseUri}/custom-protocols`, {params});
    }

    create(model: Partial<CustomProtocol>) {
        return this._http.post<CustomProtocol>(`${this._baseUri}/custom-protocols`, model);
    }

    read(key: string, detail?: DetailRequest): Observable<CustomProtocol> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<CustomProtocol>(`${this._baseUri}/custom-protocols/${key}`, {params});
    }

    update(key: string, model: Partial<CustomProtocol>) {
        return this._http.put<CustomProtocol>(`${this._baseUri}/custom-protocols/${key}`, model);
    }

    delete(key: string) {
        return this._http.delete<CustomProtocol>(`${this._baseUri}/custom-protocols/${key}`);
    }

    buildMeta(customProtocol: Partial<CustomProtocol>) {
        let currentSelection = [];

        let columns = {
            [ProtocolColumnType.Property]: [],
            [ProtocolColumnType.Characteristic]: [],
            [ProtocolColumnType.Measurement]: [],
            [ProtocolColumnType.Index]: [],
        };
        if (customProtocol.protocolSubset && customProtocol.protocolSubset.columns) {
            customProtocol.protocolSubset.columns.forEach(val => {
                columns[val.type].push(val.id);
                currentSelection.push({
                    id: val.id,
                    type: val.type,
                });
            })
        }

        return {
            characteristics: columns[ProtocolColumnType.Characteristic],
            measurements: columns[ProtocolColumnType.Measurement],
            indexes: columns[ProtocolColumnType.Index],
            currentSelection: currentSelection,
        }
    };

    toProtocol(customProtocol: Partial<CustomProtocol>) {
        let mata = this.buildMeta(customProtocol);

        let baseProtocol = this.allProtocols().all().filter( protocol => {
            return (customProtocol.baseProtocolId === protocol.id);
        });

        let protocol: Protocol = {
            id: customProtocol.key,
            title: customProtocol.title,
            description: customProtocol.description,
            sampleType: customProtocol.protocolType,
            chars: mata.characteristics,
            measures: mata.measurements,
            indexes: mata.indexes,
            crop: customProtocol.cropId,
        }

        return protocol;
    };

    allProtocols() {
        let selectedOrg = this._session.get('auth_org_key');
        let prevOrgKey = this._session.get('prev_org_key');

        if (this.protocols && selectedOrg == prevOrgKey){
            return this.protocols;
        }

        this.protocols = new ProtocolDictionary();
        this.customProtocols = []
        this.customProtocolsList = []

        // load custom protocols

        if (selectedOrg) {

            prevOrgKey = this._session.set('prev_org_key', selectedOrg);

            this.list(selectedOrg).subscribe(result => {
                result.data.forEach(row => {
                    this.protocols.add(this.toProtocol(row));
                    this.customProtocols.push(this.toProtocol(row));
                    this.customProtocolsList.push(row);
                });
            });
        }
        return this.protocols;
    }

    allProtocolsObserved(orgKey: string, excludeArchived: boolean = true) : Observable<Array<Protocol>> {
        let protocols = new ProtocolDictionary();
        let customProtocols = []
        let customProtocolsList = []

        const result: Observable<Protocol[]> = new Observable(
            (observer) => {
                this.list(orgKey).subscribe(result => {
                    result.data.forEach(row => {
                        if (excludeArchived && row.archived) return;
                        protocols.add(this.toProtocol(row));
                        customProtocols.push(this.toProtocol(row));
                        customProtocolsList.push(row);
                    });

                    observer.next(protocols.all());
                });
            }
        )

        return result;
    }

    protocolsByCropObserved(cropId: string, sampleType: string, hideArchived: boolean = false, protocolId: string = null) : Observable<Array<Protocol>> {
        let cropProtocolIds: string[] = this._library.crops.get(cropId).protocols;
        let customProtocols: Protocol[] = [];

        const result: Observable<Protocol[]> = new Observable(
            (observer) => {
                let cropProtocols:Protocol[] = this.allProtocols().all().filter( protocol => {
                    if ((cropProtocolIds.indexOf(protocol.id) !== -1) && (protocol.sampleType === sampleType)){
                        return true;
                    }
                });

                this.addCustomProtocols(customProtocols, cropId, sampleType, hideArchived, protocolId);

                customProtocols = customProtocols.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                });

                cropProtocols.push(...customProtocols);

                observer.next(cropProtocols);
            }
        )

        return result;
    }

    protocolsByCropSample(cropId: string, sampleType: string, hideArchived: boolean = false, protocolId: string = null){
        let cropProtocolIds = this._library.crops.get(cropId).protocols;

        let cropProtocols:Protocol[] = this.allProtocols().all().filter( protocol => {
            if ((cropProtocolIds.indexOf(protocol.id) !== -1) && (protocol.sampleType === sampleType)){
                return true;
            }
        });

        this.addCustomProtocols(cropProtocols, cropId, sampleType, hideArchived, protocolId);
        return cropProtocols;
    }

    private addCustomProtocols(cropProtocols, cropId, sampleType, hideArchived: boolean = false, protocolId: string = null){
        let tempCustom: CustomProtocol;
        this.customProtocolsList.forEach(customProtocol => {

            if(customProtocol.cropId == cropId && customProtocol.protocolType == sampleType) {
                tempCustom = {...customProtocol, id: customProtocol.key };
               if ((hideArchived && customProtocol.archived) && (customProtocol.key != protocolId)) return
               cropProtocols.push(tempCustom);
            }

        });
    }
}
