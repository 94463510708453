import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppBrowserModule } from "@core/browser";
import { AppChartsModule } from "@core/charts";
import { CoreDataModule } from "@core/data";
import { MapModule } from "@core/maps";
import { AppMaterialModule } from "@core/material";
import { TimelineModule } from "@core/timeline";
import { CoreUtilsModule } from "@core/utils";
import { AppAuthModule } from "./auth";
import { CultivarAutocompleteChipsComponent } from "./evaluation/components/model-autocomplete/cultivar-autocomplete-chips.component";
import { CultivarAutocompleteComponent } from "./evaluation/components/model-autocomplete/cultivar-autocomplete.component";
import { SampleAutocompleteChipsComponent } from "./evaluation/components/model-autocomplete/sample-autocomplete-chips.component";
import { SampleAutocompleteComponent } from "./evaluation/components/model-autocomplete/sample-autocomplete.component";
import { SiteAutocompleteChipsComponent } from "./evaluation/components/model-autocomplete/site-autocomplete-chips.component";
import { SiteAutocompleteComponent } from "./evaluation/components/model-autocomplete/site-autocomplete.component";
import { CultivarLineComponent } from "./evaluation/components/model-line/cultivar-line.component";
import { SampleLineComponent } from "./evaluation/components/model-line/sample-line.component";
import { SiteLineComponent } from "./evaluation/components/model-line/site-line.component";
import { ImportFormUploadComponent } from "./evaluation/components/import-form/import-form-upload.component";
import { NgxsModule } from "@ngxs/store";
import { ExportHistoryFormState } from "./evaluation/components/export-history-form/export-history-form.state";
import { ExportHistoryDialog } from "./evaluation/components/export-history-form/export-history-form.dialog";



@NgModule({

    imports: [
        CommonModule,
        CoreDataModule,
        AppMaterialModule,
        AppChartsModule,
        AppAuthModule,
        CoreUtilsModule,
        MapModule,
        TimelineModule,
        AppBrowserModule,
        NgxsModule.forFeature([
            ExportHistoryFormState,
        ]),
    ],

    providers: [
    ],

    declarations: [
        SiteLineComponent,
        SampleLineComponent,
        CultivarLineComponent,
        CultivarAutocompleteChipsComponent,
        SiteAutocompleteChipsComponent,
        SampleAutocompleteComponent,
        SampleAutocompleteChipsComponent,
        CultivarAutocompleteComponent,
        SiteAutocompleteComponent,
        ImportFormUploadComponent,
        ExportHistoryDialog,
    //    NgxDocViewerComponent,
    ],
    exports: [
        SiteLineComponent,
        SampleLineComponent,
        CultivarLineComponent,
        CultivarAutocompleteChipsComponent,
        SiteAutocompleteChipsComponent,
        SampleAutocompleteComponent,
        SampleAutocompleteChipsComponent,
        CultivarAutocompleteComponent,
        SiteAutocompleteComponent,
        ImportFormUploadComponent,
     //   NgxDocViewerComponent,
    ],
    entryComponents: [
        ExportHistoryDialog,
    ]
})
export class SharedModule {}
