import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Cultivar, Filter, FilterQueryMode, FilterSort, GroupService, isPlant, Organization, OrganizationGroup, Sample, SampleService, SampleType, Site, SiteFormDialogData, Status, TastingSampleService } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { NumberValidators } from '@core/utils';
import { Crop, Library } from '@library';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LIBRARY } from '../../library';
import { CultivarFormDialog, CultivarFormDialogData } from '../cultivar-form/cultivar-form.dialog';
import { ReportSetupFormDialog, ReportSetupFormDialogData } from '../report-builder/report-setup-form.dialog';
import { SampleFormDialog, SampleFormDialogData } from '../sample-form/sample-form.dialog';
import { SampleLabelOptions } from '../sample-labels/sample-label-options';
import { SiteFormDialog } from '../site-form/site-form.dialog';
import { SampleIndexState } from './sample-index.state';
import { ClearSampleIndexFilter, ExportSampleIndex, InitSampleIndex, LoadSampleIndex, PageSampleIndex, PushSampleIndexModelQuery, QuerySampleIndex, SetSampleIndexSelected, SetSampleLabelOptions, SortSampleIndex, DeleteSample, ImportSampleIndex, DownloadSampleImportTemplate, ExportSampleStats, ImportSampleEvaluations } from './sample-index.state-actions';
import { SampleIndexQuery, SampleIndexStateModel } from './sample-index.state-model';
import { SamplePrimaryImageFormDialogData, SamplePrimaryImageFormDialog } from '../sample-form/sample-primary-image-form.dialog';
import { EvaluationFormDialog, EvaluationFormDialogData } from '../evaluation-form/evaluation-form.dialog';
import { coerseDateProperty } from '@core/utils';
import { SampleIndexGroupFormDialog, SampleIndexGroupFormDialogData } from './sample-index-group-form.dialog';
import { AddGroup, RemoveGroupSample } from '@app/auth/components/organization-detail/groups.state';
import { OrganizationGroupFormDialog, OrganizationGroupFormDialogData } from '@app/auth/components/organization-detail/organization-group-form.dialog';
import { map } from 'rxjs/operators';
import { AuthState } from '@app/auth';
import { SampleIndexEvaluationBulkDialog, SampleIndexEvaluationBulkDialogData } from './sample-index-evaluation-bulk.dialog';
import { OrganizationSubscriptionHandler } from '@core/data/globals/subscriptions';
import { BulkTasteSampleFormDialog, BulkTasteSampleFormDialogData } from '../sample-form/bulk-taste-sample-form.dialog';
import { ExportHistoryDialog, ExportHistoryDialogData } from "@app/evaluation/components/export-history-form/export-history-form.dialog";
import { ExportType } from "@core/data/types/export-history";
import { EvaluationScheduleFormDialog, EvaluationScheduleFormDialogData } from '../evaluation-form/evaluation-schedule-form.dialog';
import { EvaluationTemplateFormDialog, EvaluationTemplateFormDialogData } from '../evaluation-form/evaluation-import-template-form.dialog';

@Component({
    selector: 'pv-sample-index-view',
    templateUrl: 'sample-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-sample-index-view'
    }
})
export class SampleIndexView implements OnInit, OnDestroy {

    @Select(AuthState.selectedOrg)
	selectedOrg$: Observable<Organization>;

    @Select(SampleIndexState)
    state$: Observable<SampleIndexStateModel>;

    @Select(SampleIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(SampleIndexState.query)
    query$: Observable<SampleIndexQuery>;

    @Select(SampleIndexState.labelOptions)
    labelOptions$: Observable<SampleLabelOptions>;

    @Select(SampleIndexState.sampleType)
    sampleType$: Observable<SampleType>;

    groups$: Observable<OrganizationGroup[]>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    // query form group
    scionCultivarsControl = new FormControl([]);
    rootstockCultivarsControl = new FormControl([]);
    sitesControl = new FormControl([]);
    birthWeekControl = new FormControl();
    birthYearControl = new FormControl();
    searchControl = new FormControl();
    scionCropIdControl = new FormControl();
    scionLicenseeControl = new FormControl();
    rowIndexControl = new FormControl(null, [NumberValidators.rangeOrSet()]);
    positionIndexControl = new FormControl(null, [NumberValidators.rangeOrSet()]);
    groupKeyControl = new FormControl([]);
    destroyedControl = new FormControl();
    excludeYearControl = new FormControl();
    startEvalPeriodControl = new FormControl(null);
    endEvalPeriodControl = new FormControl(null);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        scionCultivars: this.scionCultivarsControl,
        rootstockCultivars: this.rootstockCultivarsControl,
        sites: this.sitesControl,
        birthYear: this.birthYearControl,
        birthWeek: this.birthWeekControl,
        scionCropId: this.scionCropIdControl,
        scionLicensee: this.scionLicenseeControl,
        rowIndex: this.rowIndexControl,
        positionIndex: this.positionIndexControl,
        groupKey: this.groupKeyControl,
        hideDestroyed: this.destroyedControl,
        excludeYear: this.excludeYearControl,
        startEvalPeriod: this.startEvalPeriodControl,
        endEvalPeriod: this.endEvalPeriodControl,
    });

    bulkModifyOptionControl = new FormControl();
    bulkFieldUpdateControl = new FormControl();
    bulkFields = [
        {
            value:"destroyedDate",
            fieldType:"date",
            type: null,
            label:"Destroyed Date",
            hint:"Select a destroyed date",
            error:"Invalid Date, formatted <em>YYYY-MM-DD</em>",
        },
        {
            value:"harvestedDate",
            fieldType:"date",
            type:"harvest",
            label:"Harvest Date",
            hint:"Select a harvest date",
            error:"Harvest date required, formatted <em>YYYY-MM-DD</em>",
        },
        {
            value:"birthDate",
            fieldType:"date",
            type:"plant",
            label:"Planted Date",
            hint:"Select a planted date",
            error:"Planted date required, formatted <em>YYYY-MM-DD</em>",
        },
        {
            value:"label",
            fieldType:"text",
            type: null,
            label:"Label",
            hint:"Label to identify the sample",
            error:"Label max 32 chars",
        },
        {
            value:"size",
            fieldType:"text",
            type: null,
            label:"Size",
            hint:"Number of units in sample",
            error:"Enter integer between 1-9999",
            suffix: "#",
        },
        {
            value:"description",
            fieldType:"textArea",
            type: null,
            label:"Description",
            hint:"Describe sample or testing conditions",
            error:"Description max 255 chars",
        },
        {
            value:"rowIndex",
            fieldType:"text",
            type: "plant",
            label:"Row",
            hint:"Row # within block",
            error:"Enter a row number (1-999) [#]",
            suffix: "#",
        },
        {
            value:"positionIndex",
            fieldType:"text",
            type: "plant",
            label:"Position",
            hint:"Sample position in row",
            error:"Enter a position number (1-999) [#]",
            suffix: "#",
        },
        {
            value:"rowDistance",
            fieldType:"text",
            type: "plant",
            label:"Row Spacing",
            hint:"Distance between rows",
            error:"Enter row distance (1-99999) [mm]",
            suffix: "mm",
        },
        {
            value:"colDistance",
            fieldType:"text",
            type: "plant",
            label:"Plant Spacing",
            hint:"Distance between trees",
            error:"Enter a planting distance (1-99999) [mm]",
            suffix: "mm",
        },
    ];

    //bulk action form group
    bulkActionFormGroup = new FormGroup({
        bulkFieldUpdate: this.bulkFieldUpdateControl,
        bulkModifyOption: this.bulkModifyOptionControl,
    });

    duplicateAmountControl =  new FormControl(0, [Validators.min(0), Validators.max(50)]);
    previousDuplicate = null;

    // label options form group
    filterSelectedLabels = false;
    labelOptionsFormGroup = new FormGroup({
        layout: new FormControl('landscape'),
        size: new FormControl('small'),
        includeFields: new FormControl([]),
        margin: new FormControl(5),
        whitespace: new FormControl(false),
        duplicateAmount: new FormControl(0, [Validators.min(0), Validators.max(50)]),
    });

    fileTypeControl: FormControl = new FormControl('xlsx');

    // export form group
    exportFormGroup = new FormGroup({
        type: this.fileTypeControl,
    });

    importFormGroup = new FormGroup({
        file: new FormControl(null),
    });

    exportProtocolControl: FormControl = new FormControl(null, [Validators.required]);
    evalProtocolControl: FormControl = new FormControl(null, [Validators.required]);
    measureLimitControl: FormControl = new FormControl(20, [Validators.required, Validators.min(1), Validators.max(100)]);
    evalMeasureLimitControl: FormControl = new FormControl(20, [Validators.required, Validators.min(1), Validators.max(100)]);

    // export stats form group
    exportStatsFormGroup = new FormGroup({
        type: this.fileTypeControl,
        protocol: this.exportProtocolControl,
        measureLimit: this.measureLimitControl,
    });

    evaluationFormGroup = new FormGroup({
        protocol: this.evalProtocolControl,
        measureLimit: this.evalMeasureLimitControl,
    })

    evaluationImportFormGroup = new FormGroup({
        file: new FormControl(null),
    });

    selectedTabIndex = 0;

    yearOptions: number[] = [];
    weekOptions: number[] = [];
    cropOptions: Crop[];
    filteredCropOptions: Crop[];

    hasTastingsModule: boolean = false;

    selection = new SelectionModel<string>(true, []);

    private _destroy$ = new Subject();

    sliderValue: number = 0;

    // TODOremove this after feature has been implemented
    leftCount: number = 0;
    rightCount: number = 0;
    showEvalImport: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _store: Store,
        private _changeDetect: ChangeDetectorRef,
        private _dialog: Dialog,
        private _snackbar: Snackbar,
        private _sampleService: SampleService,
        private _groupService: GroupService,
        private _subscriptionHandler: OrganizationSubscriptionHandler,
        @Inject(LIBRARY) private _library: Library,
        private ref: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        //get tastings subscription status
        this.hasTastingsModule = this._subscriptionHandler.isSubscribedToTastings();

        // make year options [current year - 20] to [current year + 1]
        let cYear = new Date().getFullYear();
        for (let i = (cYear - 20); i <= (cYear + 1); i++) {
            this.yearOptions.push(i);
        }
        this.yearOptions.reverse();

        // make week options 1-53
        for (let i = 1; i <= 53; i++) {
            this.weekOptions.push(i);
        }

        // setup crop options
        this.cropOptions = this._library.filterExcludedCrops()

        // map route parameter changes
        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(
                    new InitSampleIndex(
                        params.get('orgKey'),
                        <SampleType>params.get('type')
                    )
                );

                if (params.get('groupKey')) {
                    this.groupKeyControl.setValue([params.get('groupKey')]);
                }
            });

        // apply state changes to query form
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => this.queryFormGroup.patchValue(query, { emitEvent: false }));

        // apply state changes to sort form
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        // apply state changes to label options form
        this.labelOptions$.pipe(takeUntil(this._destroy$))
            .subscribe(labelOptions => {
                let options = {
                    ...labelOptions,
                    margin: +labelOptions.margin.split('mm')[0],
                    duplicateAmount: labelOptions.duplicateAmount ? labelOptions.duplicateAmount : 0,
                }   //convert margin to int

                this.duplicateAmountControl.patchValue(options.duplicateAmount, {emitEvent: false})
                this.labelOptionsFormGroup.patchValue(options, { emitEvent: false })
                this.getDupicateValue()
            });

        // handle sort changes
        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortSampleIndex(val.column, val.order));
            });

        // handle query changes
        this.queryFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(val => {
                let data: SampleIndexQuery = {
                    ...val,
                    startEvalPeriod: val.startEvalPeriod ? coerseDateProperty(val.startEvalPeriod) : null,
                    endEvalPeriod: val.endEvalPeriod ? coerseDateProperty(val.endEvalPeriod) : null,
                }

                this._store.dispatch(new QuerySampleIndex(data));
            });

        // handle duplicate amount changes
        this.duplicateAmountControl.valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(200))
            .subscribe(amount => {
                let duplicate = amount ? amount : 0
                if (this.previousDuplicate == duplicate) return
                this.previousDuplicate = duplicate
                this.labelOptionsFormGroup.patchValue({
                    duplicateAmount: duplicate
                })
            })

        // handle label options changes
        this.labelOptionsFormGroup.valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(200))
            .subscribe(val => {
                val.margin = `${val.margin}mm`; //save margin as string in state
                this._store.dispatch(new SetSampleLabelOptions(val));
                this.getDupicateValue()
            });

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                if (this.selection.selected.length > 0) {
                    this.filterSelectedLabels = true;
                } else {
                    this.filterSelectedLabels = false;
                }
                this._store.dispatch(new SetSampleIndexSelected(this.selection.selected));
            });

        //setup groups
        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);
        let filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: state.orgKey,
                },
                {
                    key: 'type',
                    mode: FilterQueryMode.EQUALS,
                    value: state.sampleType,
                }
            ],
            sort: {
                column: 'label',
                order: 'asc',
            }
        };

        this.groups$ =
            this._groupService.query(filter)
                .pipe(
                    map(result => result.data)
                );

        //change group filter on index change
        this._route.paramMap
        .subscribe(params => {
            this._store.dispatch(
                new InitSampleIndex(
                    params.get('orgKey'),
                    <SampleType>params.get('type')
                )
            );

            let newFilter: Filter = filter;
            newFilter.queries[1].value = <SampleType>params.get('type');

            this.groups$ =
                this._groupService.query(newFilter)
                    .pipe(
                        map(result => result.data)
                    );

            if (params.get('groupKey')) {
                this.groupKeyControl.setValue([params.get('groupKey')]);
            }
        });

        // Ensures that selection on UI always matches the state
        this.state$.subscribe(data => {
            if (data == null) {
                this.selection.clear();
                return;
            }

            if (data.selectedKeys.length == 0) {
                this.selection.clear();
                return;
            }

            data.selectedKeys.forEach(key => {this.selection.select(key)});
        })

        if (!state.sortOptions.map(opt => opt.id).includes(this.columnControl.value)) {
            this.columnControl.setValue('createdAt')
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    reload() {
        this._store.dispatch(new LoadSampleIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageSampleIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {

        if (sort.direction) {
            this._store.dispatch(new SortSampleIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortSampleIndex('updatedAt', 'desc'));
        }
    }

    export() {
        let exportOptions = this.exportFormGroup.value;

        this.exportFormGroup.disable();

        this._store.dispatch(new ExportSampleIndex())
            .subscribe(
                global => {
                    let exp = this._store.selectSnapshot(SampleIndexState.latestExport);

                    if (exp && exp.status === Status.COMPLETE) {
                        this._snackbar.info(
                            `Export is queued. The result will be emailed to you once the export is complete.`,
                        );
                    }
                },
                e => {
                    this.exportFormGroup.enable();
                },
                () => {
                    this.exportFormGroup.enable();
                }
            );
    }

    exportHistory(orgKey: string, sampleType: string) {
        const data: ExportHistoryDialogData = {
            orgKey: orgKey,
            type: ExportType.SAMPLES,
            sampleType: sampleType
        }

        this._dialog.open(ExportHistoryDialog, { data })
    }

    exportStats() {
        if (!this.exportStatsFormGroup.valid) return this._snackbar.error("Check input and try again");

        this.exportStatsFormGroup.disable();

        const form = this.exportStatsFormGroup.value;

        this._store.dispatch(new ExportSampleStats(form.protocol, form.measureLimit))
            .subscribe(result => {
                let stats = this._store.selectSnapshot(SampleIndexState.latestStatExport);

                if (stats && stats.status === Status.COMPLETE) {
                    this._snackbar.info(
                        `Export is queued. The result will be emailed to you once the export is complete.`,
                    );
                }
            },
            e  => { this.exportStatsFormGroup.enable()},
            () => { this.exportStatsFormGroup.enable()});
    }


    setFormFile(file: File) {
        this.importFormGroup.get('file').setValue(file);
    }

    setEvalFormFile(file: File) {
        this.evaluationImportFormGroup.get('file').setValue(file);
    }

    attemptImport() {
        if (!this.importFormGroup.valid || !this.importFormGroup.value.file) {
            this.errorHandler('Invalid Input. Check your input and try again.', 'error');
            return;
        }

        let file: File = this.importFormGroup.value.file;
        this.import(file);
    }

    attemptEvalImport() {
        if (!this.evaluationImportFormGroup.valid || !this.evaluationImportFormGroup.value.file) {
            this.errorHandler('Invalid Input. Check your input and try again.', 'error');
            return;
        }

        let file: File = this.evaluationImportFormGroup.value.file;

        this.evalImport(file);
    }

    import(file: File) {
        this._store.dispatch(new ImportSampleIndex(file));
    }

    evalImport(file: File) {
        this._store.dispatch(new ImportSampleEvaluations(file));
    }

    downloadImportTemplate() {
        this._store.dispatch(new DownloadSampleImportTemplate());
    }

    openEvalTemplateDialog(orgKey: string) {
        const data: EvaluationTemplateFormDialogData = {
            orgKey: orgKey,
        }

        this._dialog.openFullscreen(EvaluationTemplateFormDialog, {data: data, disableClose: false}, true)
    }

    addModelFilter(model: Cultivar | Site, control: FormControl) {
        let list = control.value;

        // check for duplicate
        if (Array.isArray(list) && list.findIndex(m => m.key === model.key) !== -1) return;

        // append to filter
        control.setValue([...list, model]);
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this.clearCounts();
        this._store.dispatch(new ClearSampleIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
    isAllSelected(data: Sample[]) {
        return data.every((sample) => {
            return this.selection.isSelected(sample.key);
        });
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: Sample[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    addModelQuery(queryType: string, model: Site | Cultivar) {
        this._store.dispatch(new PushSampleIndexModelQuery(queryType, model));
    }

    add() {

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const data: SampleFormDialogData = {
            defaults: {
                type: state.sampleType,
                ownerOrgKey: state.orgKey
            }
        };

        this._dialog.open(SampleFormDialog, { data });
    }

    edit(sample: Sample, clone: boolean = false) {
        const data: SampleFormDialogData = { key: sample.key, clone: clone };
        this._dialog.open(SampleFormDialog, { data });
    }

    delete(sample: Sample) {

        //Get potential connected tasting samples first
        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        let stateSample = state.data.find((s) => {
            return s.key == sample.key
        })

        if(!stateSample) return;

        //If there is any associated tasting samples, block delete action
        if(stateSample.tastingSamples.length != 0) {
            this._snackbar.error("Please delete connected Tasting Samples first.");
        } else {
            this._dialog.confirm(
                `Delete Sample ${sample.code}`,
                'Are you sure you want to delete this sample and all associated evaluation data?'
            )
                .afterClosed()
                .subscribe(result => {
                    if (result) this._store.dispatch(new DeleteSample(sample.key));
                });
            }

    }

    deleteSelected(sampleKeys: string[]) {
        if (!sampleKeys.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        this._dialog.confirm(
            `Delete Selected`,
            `Are you sure you want to delete all ${ sampleKeys.length } selected samples and their associated evaluation data?`
        )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    let deleteList: DeleteSample[] = [];

                    for (let i = 0; i < sampleKeys.length; i++) {
                        deleteList.push(new DeleteSample(sampleKeys[i]));
                    }

                    this._store.dispatch(deleteList).subscribe(res => {
                        if (res) {
                            this.selection.clear();
                        }
                    });
                }
            });
    }

    extractHarvestSamples(sampleKeys: string[]) {
        if (!sampleKeys.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        this._dialog.confirm(
            `Extract Harvest Samples`,
            `Are you sure you want to extract harvest samples from all ${ sampleKeys.length } of the selected plant samples?`
        )

            .afterClosed()
            .subscribe(result => {
                if (result){
                    const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);
                    let sampleCodes:string[] = [];

                    for (let i = 0; i < state.selectedData.length; i++) {
                        sampleCodes[i] = state.selectedData[i].code;
                    }

                    const data: SampleFormDialogData = {
                        key: null,
                        defaults: {
                            ownerOrgKey : state.orgKey,
                            plantSamples: state.selectedData,
                            type        : SampleType.HARVEST,
                            massAction  : true,
                        },
                    };

                    this._dialog.open(SampleFormDialog, {data}).afterClosed().subscribe(res => {
                        if (res) {
                            // TODO: Pre select harvest samples created during bulk extraction
                            this.reload();
                            this._router.navigate([
                                '/org',
                                res.ownerOrgKey,
                                'samples',
                                'harvest'
                            ]);
                            this.selection.clear();
                        }
                    });
                }
            })
    }

    setPrimaryImage(sample: Sample) {

        const data: SamplePrimaryImageFormDialogData = { key: sample.key };
        this._dialog.open(SamplePrimaryImageFormDialog, { data });
    }

    editCultivar(cultivar: Cultivar) {
        const data: CultivarFormDialogData = { key: cultivar.key };
        this._dialog.open(CultivarFormDialog, { data });
    }

    editSite(site: Site) {
        const data: SiteFormDialogData = { key: site.key };
        this._dialog.open(SiteFormDialog, { data });
    }

    extract(sample: Sample) {

        if (!isPlant(sample)) return;

        const data: SampleFormDialogData = {
            defaults: {
                type: SampleType.HARVEST,
                plantSample: sample,
                ownerOrgKey: sample.ownerOrgKey
            }
        };

        this._dialog.open(SampleFormDialog, { data });
    }

    bulkActionSave(selectedSamples: any[]) {
        if (!selectedSamples.length) {
            this._snackbar.error("No Samples Selected");
            return;
        } else if (selectedSamples.length && !this.bulkModifyOptionControl.value) {
            this._snackbar.error("Please select a field to update");
            return;
        }

        if (this.bulkModifyOptionControl.value.fieldType === "date") {
            for (let i = 0; i < selectedSamples.length; i++) {
                this._sampleService.update(selectedSamples[i], { [this.bulkModifyOptionControl.value.value]:coerseDateProperty(this.bulkFieldUpdateControl.value) })
                    .subscribe(result => {
                        if(result) {
                            this._snackbar.message("Samples Updated");
                        }
                    });
            }
        } else {
            for (let i = 0; i < selectedSamples.length; i++) {
                this._sampleService.update(selectedSamples[i], { [this.bulkModifyOptionControl.value.value]:this.bulkFieldUpdateControl.value })
                    .subscribe(result => {
                        if(result) {
                            this._snackbar.message("Samples Updated");
                        }
                    });
            }
        }
    }

    setBulkEvaluationEnd(selected: string[]) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const data: SampleIndexEvaluationBulkDialogData = {
            orgKey: state.orgKey,
            sampleKeys: selected,
        }

        this._dialog.open(SampleIndexEvaluationBulkDialog, { data }).afterClosed()
            .subscribe(res => {
                if (res) this._snackbar.message("Evaluations end date set");
            });
    }

    bulkEvaluationEdit(selected: string[]) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        /*TODO:
            Future feature - still to implement
        */
    }

    addEvaluation(selected: string[]) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);
        let sampleCodes:string[] = [];

        for (let i = 0; i < state.selectedData.length; i++) {
            sampleCodes[i] = state.selectedData[i].code;
        }

        const data: EvaluationFormDialogData = {
            defaults: {
                sampleKey: null,
                sampleKeys: selected,
                sampleCodes,
                massAction: true,
            },
        };

        this._dialog.open(EvaluationFormDialog, {data}).afterClosed().subscribe(result => {
            if (result) {
                this.reload();
                this.selection.clear();
            }
        });
    }

    isSameCropSelected(): boolean {
        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const selectedCrops: string[] = [...new Set(
            state.selectedData.map(data => data.scionCultivar).map(cultivar => cultivar.cropId)
        )];

        return selectedCrops.length == 1;
    }

    scheduleEvaluations(selected: string[]) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const sampleCodes = state.selectedData.map(data => data.code);

        if (!this.isSameCropSelected()) return this._snackbar.error("Only one crop type can be selected at a time");

        const data: EvaluationScheduleFormDialogData = {
            defaults: {
                sampleKey: null,
                sampleCodes: sampleCodes,
                sampleKeys: selected,
                massAction: true,
            }
        };

        this._dialog.open(EvaluationScheduleFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return

                this._snackbar.message("Evaluations Scheduled");
                this.reload();
            });

    }

    addToGroup(selected: string[]) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const data: SampleIndexGroupFormDialogData = {
            orgKey: state.orgKey,
            groupType: state.sampleType,
            samples: selected,
        }

        this._dialog.open(SampleIndexGroupFormDialog, {data});
    }

    bulkRemoveFromGroup(selected: string[], groupKey: string) {
        if (!groupKey) {
            this._snackbar.error("No Group Filter Applied");
            return;
        }
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        let removeGroupSamples: RemoveGroupSample[] = [];
        //Build list of samples to remove from group
        for (let i = 0; i < selected.length; i++) {
            removeGroupSamples.push(new RemoveGroupSample(groupKey, selected[i]));
        }

        this._dialog.confirm(
            'Remove Sample from Group',
            `Are you sure you want to remove these ${selected.length} samples from the group?`,
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(removeGroupSamples)
                    .subscribe(state => {
                        this._snackbar.info("Samples removed from group");
                        this.reload();
                        this.selection.clear();
                    });
            }
        });
    }

    createSampleGroup(selected: string[], group?: Partial<OrganizationGroup>) {
        if (!selected.length) {
            this._snackbar.error("No Samples Selected");
            return;
        }

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        let fixedType: Object;

        if (state.sampleType == "plant") {
            fixedType = {value: state.sampleType, name: "Plant"};
        } else {
            fixedType = {value: state.sampleType, name: "Harvest"};
        }

        const data: OrganizationGroupFormDialogData = {
            ownerOrgKey: state.orgKey,
            group: group,
            fixedType: fixedType
        }

        this._dialog.open(OrganizationGroupFormDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._store.dispatch(new AddGroup(result))
                        .subscribe(_ => {
                            this._snackbar.info("Organisation group created");
                            this.addToGroup(selected);
                        });
                }
            });
    }

    createTastingsSamples(harvestSamples: Sample[]) {
        if (harvestSamples.length === 0) {
            this.errorHandler('No Samples Selected', 'info');
            return;
        }

        const data: BulkTasteSampleFormDialogData = {
            data: harvestSamples,
        }

        this._dialog.openFullscreen(BulkTasteSampleFormDialog, {data});
    }

    createReport() {

        const state: SampleIndexStateModel = this._store.selectSnapshot(SampleIndexState);

        const data: ReportSetupFormDialogData = {
            ownerOrgKey: state.orgKey,
            sampleKeys: state.selectedKeys,
        };

        this._dialog.open(ReportSetupFormDialog, { data })
            .afterClosed()
            .subscribe(report => {

                if (report) {
                    this._router.navigate([
                        '/org',
                        report.ownerOrgKey,
                        'reports',
                        report.key,
                        'edit'
                    ]);
                }
            });
    }

    // TODO remove this after feature has been implemented
    incrementCount(isRight: boolean) {
        if (isRight) this.rightCount += 1;
        else this.leftCount += 1;

        if (this.leftCount == 4 && this.rightCount == 3) {
            this._dialog.confirm(
                'Do you know the answer?',
                '',
                'Confirm',
                'Cancel'
            ).afterClosed().subscribe(res => {
                if (this.searchControl.value.toLowerCase() === 'mellon') this.showEvalImport = true;
                this.clearCounts();
                this.ref.detectChanges();
            });
        }
    }

    clearCounts() {
        this.leftCount = 0;
        this.rightCount = 0;
    }

    private errorHandler(message: string, type: string) {
        switch (type) {
            case 'error':
                this._snackbar.error(message);
                break;
            case 'info':
                this._snackbar.info(message);
                break;
            case 'message':
                this._snackbar.message(message);
                break;
            default:
                this._snackbar.info(message);
                break;
        }
    }

    private getDupicateValue(): any {
        this.sliderValue = this.labelOptionsFormGroup.value.duplicateAmount;
    }
}