import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { convertImageFileRef, SampleImage } from '@core/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';



export interface ImageGalleryDialogData {
    currentIndex?: number;
    images: SampleImage[];
}

@Component({
    selector: 'pv-image-gallery-dialog',
    templateUrl: 'image-gallery.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: true,
    host: {
        class: 'pv-image-gallery-dialog pv-fullscreen-dialog'
    }
})
export class ImageGalleryDialog implements OnInit, OnDestroy {

    @ViewChild('imagePreview', {static: false})
    imagePreviewEl: ElementRef<HTMLImageElement>;

    @ViewChild('scrollViewport', {static: true})
    scrollViewport: CdkVirtualScrollViewport;

    currentIndex$ = new BehaviorSubject<number>(0);
    currentImage$: Observable<SampleImage>;
    images: SampleImage[];
    expanded = false;

    downloads = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ImageGalleryDialogData,
        private _changeDetector: ChangeDetectorRef,
        private _dialog: MatDialogRef<ImageGalleryDialog>
    ){}

    ngOnInit(){

        this.images = this._data.images;

        this.currentIndex$.next(this._data.currentIndex);

        this.currentImage$ =
            this.currentIndex$
            .pipe(
                map(index => {

                    if(
                        !Array.isArray(this.images) ||
                        this.images.length === 0
                    ) return null;

                    if(this.images[index] !== undefined) return this.images[index];
                    return this.images[0];

                }),
                tap(image => {
                    this.updateDownloadSizes(image);
                    this._changeDetector.markForCheck();
                })
            );

        // delay selecting image to give
        // content a chance to layout
        setTimeout(() => {
            this.selectImage(this._data.currentIndex);
        }, 400);


    }

    ngOnDestroy(){
        this.currentIndex$.complete();
    }

    trackImage(index, image){
        return image.key;
    }

    prevImage(){
        this.selectImage(this.currentIndex$.getValue() - 1);
    }

    nextImage(){
        this.selectImage(this.currentIndex$.getValue() + 1);
    }

    selectImage(index: number){

        if(index < 0){
            index = this.images.length - 1;
        }else if(index >= this.images.length){
            index = 0;
        }

        this.expanded = false;
        this.currentIndex$.next(index);

        this.scrollViewport.checkViewportSize();
        let size = this.scrollViewport.getViewportSize();
        let itemsInViewport = size / 56;


        if(this.images.length > itemsInViewport){
            // center selected item
            this.scrollViewport.scrollToOffset(index * 56 - (size / 2 - 28));
        }


    }

    toggleExpand(){
        this.expanded = !this.expanded;
    }

    close(){
        this._dialog.close();
    }

    private updateDownloadSizes(image: SampleImage){

        if(!image){
            this.downloads = [];
            return;
        }

        const fileRef = image.fileRef;
        const width = image.width;
        const height = image.height;

        let scale = 'height';

        if(height > width){
            scale = 'width';
        }

        this.downloads = [];

        DEFAULT_DOWNLOAD_SIZES.forEach(size => {

            if(scale === 'width' && size.max > width){
                return;
            }else if(scale === 'height' && size.max > height){
                return;
            }

            let opts = {
                quality: 70,
                mode: 'scale',
                format: 'jpg',
                attachment: true
            };

            opts[scale] = size.max;

            this.downloads.push({
                name: `${size.name} (${scale} ${size.max}p)`,
                url: convertImageFileRef(fileRef, opts)
            });
        });

        let size = (height !== null && width !== null) ? `${height}p x ${width}p` : ``;

        this.downloads.push({
            name: `Original ` + size,
            url: convertImageFileRef(fileRef, {attachment: true, quality: 90})
        });

        this.downloads.push({
            name: `Square (1280p)`,
            url: convertImageFileRef(fileRef, {attachment: true, width: 1280, height: 1280, mode: 'fill', gravity: 'center', quality: 70})
        });


    }

}

const DEFAULT_DOWNLOAD_SIZES = [
    {
        max: 2400,
        name: 'X Large',
    },
    {
        max: 1920,
        name: 'Large',
    },
    {
        max: 1024,
        name: 'Medium',
    },
    {
        max: 480,
        name: 'Small',
    }
];
