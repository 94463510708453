import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_BASE_URI } from '../http/api';
import { Collection } from '../http/collection';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { Filter, FilterBuilder } from '../http/filter';
import { Evaluation, SampleCharacteristic, SampleImage, SampleMeasurement, SampleNote } from '../types/evaluation';
import { SampleType } from '../public_api';
import { EvaluationTemplateForm } from '@app/evaluation/components/evaluation-form/evaluation-import-template-form.dialog.state';

export type EvaluationUpdateRequest = Partial<Evaluation> | CharacteristicUpdateRequest | MeasurementsUpdateRequest | NotesUpdateRequest | ImagesUpdateRequest;


export interface CharacteristicUpdateRequest {
    chars: Partial<SampleCharacteristic>[];
}
export interface MeasurementsUpdateRequest {
    measures: Partial<SampleMeasurement>[];
}

export interface NotesUpdateRequest {
    notes: Array<Partial<SampleNote>>;
}

export interface ImagesUpdateRequest {
    images: Array<Partial<SampleImage>>;
}

export interface MeasurementsConvertRequest {
    file: File;
    format: string;
    indexOffset: number;
}

export interface MeasurementsConvertResponse {
    measures: Partial<SampleMeasurement>[];
}

export interface EvaluationCreateManyRequest {
    evals: Partial<Evaluation>[];
}


export class EvaluationUpdated {
    static readonly type = "[EvaluationService] Evaluation Updated";
    constructor(public evaluation: Evaluation){}
}

export class EvaluationDeleted {
    static readonly type = "[EvaluationService] Evaluation Deleted";
    constructor(public evaluation: Evaluation){}
}
export class EvaluationCreated {
    static readonly type = "[EvaluationService] Evaluation Created";
    constructor(public evaluation: Evaluation){}
}

export class EvaluationImageUploaded {
    static readonly type = "[EvaluationService] Evaluation Image Uploaded";
    constructor(public image: SampleImage){}
}

export interface EvaluationImportResponse {
    response: string;
}

export interface EvaluationTemplateResponse {
    response: Blob
}

export class EvaluationsImported {
    static readonly type = "[EvaluationService] Evaluations Imported";
    constructor(public response: EvaluationImportResponse) {}
}

export class EvaluationTemplateCreated {
    static readonly type = "[EvaluationService] Template Created";
    constructor(public response: Blob) {}
}


@Injectable()
export class EvaluationService {

    constructor(
        @Inject(API_BASE_URI) private _baseUri: string,
        private _http: HttpClient,
        private _store: Store
    ){}

    query(filter?: Filter, detail?: DetailRequest){
        let params = {
            ...(filter ? FilterBuilder.queryParams(filter) : {}),
            ...(detail ? DetailRequestBuilder.queryParams(detail) : {})
        };

        return this._http.get<Collection<Evaluation>>(`${this._baseUri}/evaluations`, {params});
    }

    get(key: string, detail?: DetailRequest){
        let params = detail ? DetailRequestBuilder.queryParams(detail) : {};

        return this._http.get<Evaluation>(`${this._baseUri}/evaluations/${key}`, {params});
    }

    create(data: Partial<Evaluation>){
        return this._http.post<Evaluation>(`${this._baseUri}/evaluations`, data)
            .pipe(tap(result => this._store.dispatch(new EvaluationCreated(result))));
    }

    createMany(data: EvaluationCreateManyRequest){
        return this._http.post<Evaluation[]>(`${this._baseUri}/evaluations/many`, data)
            .pipe(tap(result => {

                if(Array.isArray(result) && result.length > 0){
                    result.forEach(evalu =>
                        this._store.dispatch(new EvaluationCreated(evalu))
                    );
                }

            }));
    }

    update(key: string, data: EvaluationUpdateRequest){
        return this._http.put<Evaluation>(`${this._baseUri}/evaluations/${key}`, data)
            .pipe(tap(result => this._store.dispatch(new EvaluationUpdated(result))));
    }

    delete(key: string){
        return this._http.delete<Evaluation>(`${this._baseUri}/evaluations/${key}`)
                .pipe(tap(result => this._store.dispatch(new EvaluationDeleted(result))));
    }

    uploadImage(key:string, file: File): Observable<HttpEvent<SampleImage>>{

        let data = new FormData();
        data.set('file', file);

        const req = new HttpRequest('POST', `${this._baseUri}/evaluations/${key}/images`, data, {
            reportProgress: true
        });

        return this._http.request<SampleImage>(req)
                    .pipe(tap(result => {

                        if(result instanceof HttpResponse){
                            this._store.dispatch(new EvaluationImageUploaded(result.body));
                        }

                    }));
    }

    convertMeasurementsFile(request: MeasurementsConvertRequest) {

        let data = new FormData();
        data.set('file', request.file);
        data.set('format', request.format);
        data.set('indexOffset', '' + request.indexOffset);

        return this._http.post<MeasurementsConvertResponse>(`${this._baseUri}/convert-measurements`, data);
    }

    downloadEvaluationImportTemplate(params: EvaluationTemplateForm[]) {
        return this._http.post<Blob>(`${this._baseUri}/evaluations/import/template`, {params}, {
            responseType: 'blob' as 'json'
        }).pipe(tap(result => {
            this._store.dispatch(new EvaluationTemplateCreated(result));
        }))
    }

    import(orgKey: string, file: File) {
        let data = new FormData();
        data.set('file', file);
        data.set('ownerOrgKey', orgKey);

        return this._http.post<EvaluationImportResponse>(`${this._baseUri}/evaluations/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new EvaluationsImported(result));
            }));
    }
}
