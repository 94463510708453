import { Crop } from './_types';

export const LIB_CROPS: Crop[] = [

    // POME FRUIT

    {
        id: "apple",
        label: "Apple",
        family: "Pome Fruit",
        scientificName: "Malus Domestica",
        protocols: [
            "apple_tree_screen_eval",
            "apple_tree_breed_eval",
            "apple_harvest_screen_eval",
            "apple_harvest_breed_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "APL_42S7",
            "APL_84S7"
        ]
    },
    {
        id: "pear",
        label: "Pear",
        family: "Pome Fruit",
        scientificName: "Pyrus Communis",
        protocols: [
            "pear_tree_screen_eval",
            "pear_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "PYR_42S7",
            "PYR_84S7"
        ]
    },
    {
        id: "quince",
        label: "Quince",
        family: "Pome Fruit",
        scientificName: "Cydonia oblonga",
        protocols: [
            "apple_tree_screen_eval",
            "apple_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "APL_42S7",
            "APL_84S7"
        ]
    },

    // STONE FRUIT

    {
        id: "peach",
        label: "Peach",
        family: "Stone Fruit",
        scientificName: "Prunus Persica",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "PEC_28S7",
            "PEC_35S7",
            "PEC_42S7",
        ]
    },
    {
        id: "nectarine",
        label: "Nectarine",
        family: "Stone Fruit",
        scientificName: "Prunus Persica",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "NEC_28S7",
            "NEC_35S7",
            "NEC_42S7",
            "STONE_STERI",
        ]
    },
    {
        id: "plum",
        label: "Plum",
        family: "Stone Fruit",
        scientificName: "Prunus Salicina",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "PLM_28S7",
            "PLM_35S7",
            "PLM_33R9S7",
            "PLM_33R7S7",
            "PLM_35R7S7",
            "PLM_42S7",
            "STONE_STERI",
        ]
    },
    {
        id: "apricot",
        label: "Apricot",
        family: "Stone Fruit",
        scientificName: "Prunus Armeniaca",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "APR_28S4",
            "APR_35S7",
            "APR_42S4"
        ]
    },
    {
        id: "prune",
        label: "Prune",
        family: "Stone Fruit",
        scientificName: "Prunus Domestica",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "GEN_42S7",
        ]
    },
    {
        id: "cherry",
        label: "Cherry",
        family: "Stone Fruit",
        scientificName: "Prunus Avium",
        protocols: [
            "cherry_tree_screen_eval",
            "cherry_harvest_screen_eval",
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "CHR_35S7",
            "CHR_28S4",
        ]
    },

    // GRAPES

    {
        id: "grape",
        label: "Grape",
        family: "Grape",
        scientificName: "Vitis vinifera",
        protocols: [
            "grape_vine_screen_eval",
            "grape_harvest_screen_eval",
            "grape_harvest_raisin_eval",
            "grape_harvest_raisin_dry_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            'GRA_63S7',
            'GRA_56S7',
            'GRA_42S7',
            'GRA_35S7',
            'GRA_28S7',
        ]
    },


    // INTERSPECIE

    {
        id: "inter_stone",
        label: "Stone Fruit Interspecie",
        family: "Stone Fruit",
        scientificName: "Prunus",
        protocols: [
            "stone_tree_screen_eval",
            "stone_harvest_screen_eval",
            "harvest_impression_eval"
        ],
        schedules: [
            "GEN_42S7",
        ]
    },

    // HYBRID

    {
        id: "hybrid_citrus",
        label: "Citrus Hybrid",
        family: "Citrus",
        scientificName: "",
        protocols: [
            "citrus_tree_screen_eval",
            "citrus_harvest_eatqual_eval",
        ],
        schedules: [
            "GEN_42S7",
        ]
    },

    // CANNABIS

    {
        id: "cannabis",
        label: "Cannabis",
        family: "Cannabaceae",
        scientificName: "Cannabis",
        protocols: [
            "cannabis_plant_screen_eval",
            "cannabis_harvest_screen_eval",
        ],
        schedules: [
            "CAN_ALTO",
        ]
    },

    // MACADAMIA

    {
        id: "macadamia",
        label: "Macadamia",
        family: "Proteaceae",
        scientificName: "Macadamia",
        protocols: [
            "macadamia_tree_screen_eval",
            "macadamia_harvest_screen_eval",
        ],
        schedules: [

        ],

    },

     // BLUEBERRY

     {
        id: "blueberry",
        label: "Blueberry",
        family: "Ericaceae",
        scientificName: "Vaccinium corymbosum",
        protocols: [
            "blueberry_harvest_screen_eval",
            "blueberry_bush_screen_eval"
        ],
        schedules: [
            "BLU_21S7",
        ],

    },

    // CITRUS

    {
        id: "citrus",
        label: "Citrus",
        family: "Rutaceae",
        scientificName: "Citrus",
        protocols: [
            "citrus_advanced_harvest_eval",
            "citrus_advanced_tree_eval",
        ],
        schedules: [
            "CIT_28S7",
            "CIT_28S4",
        ]
    },

    //AVO

    {
        id: "avocado",
        label: "Avocado",
        family: "Lauraceae",
        scientificName: "Persea americana",
        protocols: [
            "avocado_harvest_screen_eval",
            "avocado_tree_screen_eval",
        ],
        schedules: [
            "AVO_28S10",
        ]
    }
]