import { formatDate } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Address, Cultivar, Sample, Site, WeatherStation, SampleImage, ReportSampleSummary } from '@core/data';
import { Coordinates, Direction, isEmpty } from "@core/utils";


@Injectable()
export class SampleFormatter {

    constructor(@Inject(LOCALE_ID) private locale: string) { }

    describeSample(sample: Sample | ReportSampleSummary, html: boolean = false, includeBirthYear: boolean = true, isChip: boolean = false) {

        if (sample === null || sample === undefined) return '';

        let desc = '';
        let hasSite = sample.hasOwnProperty("site");
        let hasCultivar = sample.hasOwnProperty("scionCultivar");

        if (html) desc += '<div>';

        //? Label Section
        if (html) desc += '<div><b>';
        if("label" in sample && sample.label) desc += `${sample.label}`;
        if("sampleLabel" in sample && sample.sampleLabel) desc += ` ${sample.sampleLabel}`;
        if (html) desc += '</b></div>';

        if (isChip) return desc;

        //? Scion Cultivar Section
        if (html) desc += '<div><small>';
        if ("scionCultivar" in sample && sample.scionCultivar) {
            if(sample.scionCultivar){
                desc += ` ${sample.scionCultivar.commonName}`;
            }
        }
        if (html) desc += '</small></div>';

        if (includeBirthYear) {
            if (!hasSite || !hasCultivar || sample.scionCultivar) {
                //? Date Section
                if ("type" in sample && sample.type) {
                    if (html) desc += '<div><small>';
                    desc += sample.type === 'plant'
                        ? this.describeHarvestYear(sample)
                        : this.describeHarvestWeek(sample) + ', ' + this.describeHarvestYear(sample);
                    if (html) desc += '</small></div>';
                }

                //? Code Section
                if (html) desc += '<div><small>';
                if (sample.scionCultivar) {
                    desc += '';
                } else {
                    if ("code" in sample) desc += sample.code;
                    if ("sampleCode" in sample) desc += sample.sampleCode;
                    if (html) desc += '</small></div>';
                }
            }
        }

        //? Site Section
        if (html) desc += '<div><small>';
        if ("site" in sample && sample.site) {
            desc += ` ${sample.site.block}, ${sample.site.name}`;
        }
        if (html) desc += '</small></div>';

        if (html) desc += '</div>';

        return desc;
    }


    describeSite(site: Site, html = false) {
        let parts = [];
        let desc = '';

        if (site) {

            if(html) desc += '<b>';
            desc += site.name;
            if(html) desc += '</b>';

            desc += ', ' + site.block + ', ';

            if (site.addrCity) parts.push(site.addrCity);
            if (site.addrCountry) parts.push(site.addrCountry);

            if(parts.length > 0){
                if (html) desc += ' <small>';
                desc += ' ' + parts.join(', ');
                if (html) desc += '</small>';
            }

        }

        return desc;
    }

    describeSiteAddress(site: Address) {

        if (!site) return '';

        let parts = [];
        if (site.addrStreet) parts.push(site.addrStreet);
        if (site.addrCity) parts.push(site.addrCity);
        if (site.addrRegion) parts.push(site.addrRegion);
        if (site.addrCode) parts.push(site.addrCode);
        if (site.addrCountry) parts.push(site.addrCountry);
        return parts.join(', ');
    }

    describeSiteCoordinates(site: Site | WeatherStation | SampleImage) {
        let parts = [];

        if (site) {

            if(isEmpty(site.lat) || isEmpty(site.lng)){
                return null;
            }

            const lat = Coordinates.transformDecimalToDegrees(site.lat);
            const latDir = Coordinates.getCardinalDirection(site.lat, Direction.LATITUDE);
            parts.push(lat + ' ' + latDir);

            const lng = Coordinates.transformDecimalToDegrees(site.lng);
            const lngDir = Coordinates.getCardinalDirection(site.lng, Direction.LONGITUDE);
            parts.push(lng + ' ' + lngDir);

            if (!isEmpty(site.alt)) {
                parts.push('alt ' + site.alt + 'm');
            }
            return parts.join(', ');
        }

        return null;

    }

    describeCultivar(cultivar: Cultivar, html: boolean = false, isChip: boolean = false): string {
        let desc = '';

        if (!cultivar) return desc;

        let hasSamples = cultivar.hasOwnProperty("scionSamples") && Array.isArray(cultivar.scionSamples) && cultivar.scionSamples.length > 0;

        if (hasSamples && !isChip) {
            return this.describeScionCultivar(cultivar, html); // Call describeScionCultivar if scionSamples exist
        } else {
            if (html) desc += '<strong>';
            desc += cultivar.commonName || 'Unknown';
            if (html) desc += '</strong>';
            return desc;
        }

    }

    describeScionCultivar(cultivar: Cultivar, html = false): string {
        let desc = '';
        if (!cultivar) return '';

        // Label Section
        if (html) desc += '<b>';
        desc += cultivar.commonName;
        if (html) desc += '</b>';

        return desc;
    }

    assignCultivarSamples(cultivar: Cultivar): Cultivar {
        // Iterate over each cultivar's scionSamples and compare the cultivar key and sample in scionSamples scionCultivarKey if true then return cultivar with scion sample appended to the correct cultivar
        if (cultivar && cultivar.scionSamples) {
            cultivar.scionSamples.forEach(sample => {
                if (sample.scionCultivarKey === cultivar.key) {
                    cultivar.scionSample = sample;
                }
            });
        }
        return cultivar;
    }


    describePlantSampleCount(cultivar: Cultivar) {
        let desc = '';

        if (cultivar) {
            if (cultivar.scionPlantSamplesCount) desc += cultivar.scionPlantSamplesCount;
        }

        return desc;
    }

    describeHarvetSampleCount(cultivar: Cultivar) {
        let desc = '';

        if (cultivar) {
            if (cultivar.scionHarvestSamplesCount) desc += cultivar.scionHarvestSamplesCount;
        }

        return desc;
    }
    describeRootstockPlantSamplesCount(cultivar: Cultivar) {
        let desc = '';

        if (cultivar) {
            if (cultivar.rootstockPlantSamplesCount) desc += cultivar.rootstockPlantSamplesCount;
        }

        return desc;
    }
    describeRootstockHarvestSampleCount(cultivar: Cultivar) {
        let desc = '';

        if (cultivar) {
            if (cultivar.rootstockHarvestSamplesCount) desc += cultivar.rootstockHarvestSamplesCount;
        }

        return desc;
    }



    describeLicensee(cultivar: Cultivar) {
        let desc = '';

        if (cultivar) {
            if (cultivar.licensee) desc += cultivar.licensee;
        }

        return desc;
    }

    describeRowPosition(sample: Sample) {
        let rowPos = '';

        if (sample) {
            if (sample.rowIndex !== null) {
                rowPos += `R${sample.rowIndex}`;
            }
            if (sample.positionIndex) {
                rowPos += `.${sample.positionIndex}`;
            }

        }

        return rowPos;
    }

    describeHarvestWeek(sample: Sample) {

        if (sample && sample.birthDate) {
            return 'W ' + formatDate(sample.birthDate, 'w', this.locale);
        }
        return '';

    }

    describeHarvestYear(sample: Sample) {
        if (sample && sample.birthDate) {
            return formatDate(sample.birthDate, 'yyyy', this.locale);
        }
        return '';

    }

}